<template>
  <div class="receipt">
    <div class="receipt-list">
      <div class="receipt-item">
        <div class="receipt-label">
          Date
        </div>
        <div class="receipt-value">
          {{ missionDate }}
        </div>
      </div>
      <div class="receipt-item">
        <div class="receipt-label">
          Amount
        </div>
        <div
          v-if="activity.sum > 0"
          class="receipt-value"
        >
          <span style="margin-right: 10px;">Reward</span>
          <span>&pound;{{ formatPrice(activity.sum) }}</span>
        </div>
        <div
          v-else
          class="receipt-value"
        >
          - &pound;{{ formatPrice(Math.abs(activity.sum)) }}
        </div>
      </div>
      <div class="receipt-item">
        <div
          class="receipt-label"
          style="float: none;"
        >
          Description:
        </div>
        <div
          class="receipt-value"
          style="float: left;margin-top: 5px;"
        >
          {{ activity.description }}
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="childActivityItem">
    <div class="childActivityItem__date">
      <div class="childActivityItem__date__title">Date</div>
      <div class="childActivityItem__date__subtitle">{{ missionDate }}</div>
    </div>
    <div class="childActivityItem__amount">
      <div class="childActivityItem__amount__title">Amount</div>
      <div v-if="activity.sum > 0" class="childActivityItem__amount__subtitle">
        <span>Reward</span>
        <span>&pound;{{ formatPrice(activity.sum) }}</span>
      </div>
      <div v-else class="childActivityItem__amount__subtitle">
        - &pound;{{ formatPrice(Math.abs(activity.sum)) }}
      </div>
    </div>
    <div class="childActivityItem__description">
      <div class="childActivityItem__description__title">Description:</div>
      <div class="childActivityItem__description__subtitle">
        {{ activity.description }}
      </div>
    </div>
  </div> -->
  <!-- 
  <tr>
    <td
      scope="row"
      data-label="Date"
    >{{missionDate}}</td>
    <td data-label="Description">{{activity.description}}</td>
    <td
      data-label="Amount"
      class="logsAmount"
    >
      <span
        v-if="activity.sum > 0"
        class="logsSpan"
      >
        <span style="text-align: left;align-self: flex-start;justify-content: flex-start;">Reward</span>
        <span>&pound;{{formatPrice(activity.sum)}}</span>
      </span>
      <span v-else>- &pound;{{formatPrice(Math.abs(activity.sum))}}</span>
    </td>
  </tr> -->
</template>

<script>
export default {
  props: {
    iconSrc: {
      default: "",
      type: String,
    },
    activity: {
      default: () => [],
      type: Object,
    },
  },
  computed: {
    missionDate() {
      var parts = this.activity.date.split("-");
      var mydate = new Date(parts[0], parts[1] - 1, parts[2]);
      // console.log(mydate.toDateString());
      return mydate.toDateString();
    },
  },
};
</script>

<style lang="scss">
.appChildrenActivity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 60px);
  margin: 0 auto;
  padding: 20px 0;

  &__profile {
    display: flex;
    align-items: center;

    &__image {
      display: block;
      max-width: 50px;
      max-height: 50px;
      width: auto;
      height: auto;
    }

    &__title {
      //   font-family: Roboto;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      color: #7a615a;
      font-size: 18px;
      line-height: 21px;
      margin-left: 15px;
    }
  }

  &__close {
    display: block;
    max-width: 15px;
    max-height: 15px;
    width: auto;
    height: auto;
    cursor: pointer;
  }
}

#childrenActivity table {
  // border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  color: #7a615a;
}

#childrenActivity table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

#childrenActivity table tr {
  // background-color: #f8f8f8;
  border-bottom: 1px solid #f3efe2;
  padding: 0.35em;
  color: #7a615a;
}

#childrenActivity table th,
#childrenActivity table td {
  padding: 0.625em;

  // text-align: center;
}

#childrenActivity table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border-radius: 40px;
  text-align: left;
  font-weight: 100;
  &:first-child {
    width: 170px;
  }
  &:last-child {
    width: 180px;
    text-align: right;
  }
}

@media screen and (max-width: 600px) {
  #childrenActivity table {
    border: 0;
  }

  #childrenActivity table caption {
    font-size: 1.3em;
  }

  #childrenActivity table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  #childrenActivity table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  #childrenActivity table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  #childrenActivity table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  #childrenActivity table td:last-child {
    border-bottom: 0;
  }
}

.logsAmount {
  display: flex;
  justify-content: flex-end;

  .logsSpan {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.childActivityItem {
  margin-bottom: 20px;
  padding-bottom: 20px;
  color: var(--secondary);
  border-bottom: 1px dashed var(--secondary);

  &__description {
    &__title {
      font-weight: 600;
      font-size: 13px;
    }
    &__subtitle {
      // font-weight: 600;
      font-size: 12px;
    }
  }

  &__amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    &__title {
      font-weight: 600;
      font-size: 13px;
    }

    &__subtitle {
      // font-weight: 600;
      font-size: 12px;
    }
  }

  &__date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    &__title {
      font-weight: 600;
      font-size: 13px;
    }

    &__subtitle {
      // font-weight: 600;
      font-size: 12px;
    }
  }
}

.receipt {
  background: linear-gradient(135deg, transparent 5.68px, white 5.69px) top left, linear-gradient(45deg, white 2.8px, transparent 2.81px) top left, linear-gradient(135deg, white 2.8px, transparent 2.81px) bottom left, linear-gradient(45deg, transparent 5.68px, white 5.69px) bottom left;
  background-repeat: repeat-x;
  background-size: 8px 4px;
  padding: 4px 0;
}
.receipt-list {
  background-color: white;
}

.receipt {
  filter: drop-shadow(0 0px 2px rgba(0, 0, 0, 0.1));
  // max-width: 320px;
  margin: 0 auto;
}
.receipt-list {
  background-color: #fff;
  padding: 30px;
}
.receipt-item {
  border-bottom: 0.5px dashed #ccc;
  line-height: 1;
  overflow: hidden;
  font-family: monospace;
  font-size: 16px;
  padding-bottom: 10px;
}
.receipt-item:not(:first-child) {
  margin-top: 1rem;
}
.receipt-label {
  float: left;
  font-weight: bold;
  color: #555;
  font-size: 13px;
}
.receipt-value {
  float: right;
  font-size: 13px;
  color: var(--secondary);
}
</style>