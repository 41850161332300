<template>
  <div @input="exportPin">
    <PincodeInput
      v-model="value"
      :autofocus="false"
    />
  </div>
</template>

<script>
import PincodeInput from "vue-pincode-input";
export default {
  components: {
    PincodeInput,
  },
  data() {
    return {
      value: "",
    };
  },
  watch: {
    value() {
      if (this.value.length === 4) {
        this.$emit("enter", this.value);
      }
    },
  },
  methods: {
    exportPin() {
      const pin = this.value;

      if (pin.length === 4) {
        this.$emit("enter", pin);
      }

      // console.log(pin.length);
      // console.log(event);
      // console.log(this.value);
    },
  },
};
</script>

<style lang="scss">
.vue-pincode-input {
  margin: 5px !important;
}

input.vue-pincode-input {
  background: white;
  width: 55px;
  height: 55px;
  min-height: 55px;
  min-width: 55px;
  color: #ff4f79;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 60px;
  font-weight: 900;
  box-shadow: 0 0 2px .5px var(--red);
  font: {
    size: 25px;
  }
}
</style>
