<template>
  <div>
    <div
      class="main top-[60px] z-[2] pt-2"
      style="display: block; height: auto; width: 100%"
    >
      <div
        v-if="Object.keys(getSetupChildrens).length > 0"
        class="stories"
        style="margin-top: 0"
      >
        <div
          v-for="(child, index) in getSetupChildrens"
          :key="child.id"
          @click="childActivate(child.id, child.balance, child.username)"
          class="stories__item"
          :class="{
            'stories__item--active': child.id == activeChild,
          }"
          style="position: relative"
          ref="childRef"
        >
          <button>
            <div class="stories__item-button">
              <span
                class="stories__item-username px-4"
                :class="{
                  'stories__item--active': child.id == activeChild,
                }"
                >{{ child.username
                }}<svg
                  class="stories__item-manage"
                  v-if="child.id == activeChild"
                  @click="openEditModal(index)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#01a4c0"
                    d="M5 23.7q-.825 0-1.413-.587T3 21.7v-14q0-.825.588-1.413T5 5.7h8.925l-2 2H5v14h14v-6.95l2-2v8.95q0 .825-.588 1.413T19 23.7H5Zm7-9Zm4.175-8.425l1.425 1.4l-6.6 6.6V15.7h1.4l6.625-6.625l1.425 1.4l-7.2 7.225H9v-4.25l7.175-7.175Zm4.275 4.2l-4.275-4.2l2.5-2.5q.6-.6 1.438-.6t1.412.6l1.4 1.425q.575.575.575 1.4T22.925 8l-2.475 2.475Z"
                  />
                </svg>
              </span>
            </div>
          </button>
        </div>
        <div>
          <button @click="addChildMethod">
            <div class="stories__item-picture">
              <div class="addChildButton">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#fff"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
              </div>
            </div>
          </button>
        </div>
      </div>
      <section v-else class="stories" style="padding-bottom: 20px">
        <div class="stories__item">
          <button @click="addChildMethod">
            <div class="stories__item-picture">
              <div class="addChildButton">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#fff"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="5"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
              </div>
            </div>
            <span class="stories__item-username" style="color: var(--main)"
              >Add New</span
            >
          </button>
        </div>
      </section>
    </div>

    <div v-if="Object.keys(getSetupChildrens).length > 0">
      <section
        id="balance"
        style="
          padding: 0px 20px;
          background: linear-gradient(180deg, #01a4c0 0%, #006779 100%);
        "
        class="relative"
      >
        <div class="balance_text">
          <div class="balance_text__amount">
            &pound;{{ formatPrice(childBalance) }}
          </div>

          <div class="balance_text__title">{{ childUsername }}'s Balance</div>
        </div>
      </section>
      <!-- <section
      style="padding: 0px 20px; background-color: #56B897;"
      class="relative"
      
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;
          padding: 10px;
        "
      >
        <div style="color: var(--secondary); font-weight: 700; font-size: 18px">
          {{ childUsername }}'s Balance
        </div>
        <div class="balance_text__amount" style="color: var(--secondary); font-weight: 700; font-size: 18px">
          &pound;{{ formatPrice(childBalance) }}
        </div>
      </div>
      </section> -->
      <div v-if="Object.keys(getSetupChildrens).length > 0">
        <div v-if="transactionsGetter.length != 0">
          <AppChildrenActivity
            id="childrenActivity"
            v-for="(activity, index) in transactionsGetter"
            :key="index"
            :activity="activity"
            :iconSrc="childIcon"
            @delete="deleteLogSubmit(activity.id)"
            class="dashboardChildren__container__activity__element"
            style="margin-bottom: 10px"
          />
        </div>
        <h1 class="normal-text-title">Activities</h1>
        <h3 class="normal-text">No activities found</h3>
      </div>
      <div v-else style="display: inline-block; width: 100%">
        <h3 class="no-activity-found-title">First add a child</h3>
      </div>
    </div>

    <div v-if="Object.keys(getSetupChildrens).length > 0">
      <div v-if="missionWishlistGetter.length > 0" style="margin-bottom: 10px">
        <div style="width: 100%; margin-top: 20px">
          <div
            style="
              margin: 35px 0 10px 0;
              color: var(--secondary);
              font-weight: 700;
            "
          >
            {{ childUsername }}'s Wishlist
          </div>

          <div
            v-if="missionWishlistGetter.length > 0"
            class="walletScroll"
            style="padding: 15px 5px"
          >
            <div
              class="goal-item walletScroll__item__3"
              v-for="item in missionWishlistGetter"
              :key="item.id"
            >
              <div class="goal-img">
                <img
                  :src="resolveImage(item.default_picture_link)"
                  alt="Image"
                />
              </div>
              <div class="goal-description">
                <h1>{{ item.name }}</h1>
                <p>&pound;{{ formatPrice(item.price) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="margin-bottom: 30px; padding-top: 20px">
        <div class="normal-text-title">Wishlist</div>
        <div class="normal-text">
          Your child has not added any wishlist items. Please remind
          {{ childUsername }} to add items to their wishlist.
        </div>
      </div>
    </div>
    <!------------------------------------------------------>

    <Sheet
      :show.sync="sheetChildAdd"
      :on-close="closeSheetAddChild"
      :isAddingChild="true"
    >
      <div class="sticky top-0 flex justify-between items-center">
        <button
          v-if="currentstep != 1"
          class="w-[20px] h-[20px]"
          @click="stepBack(currentstep)"
        >
          <img src="../../assets/images/back-arrow.svg" alt="Close" />
        </button>
        <div v-else class="w-[20px] h-[20px]"></div>
        <div class="bottomsheet-title">
          <StepNavigation
            :steps="steps"
            :currentstep="currentstep"
            style="min-width: 200px !important"
          >
          </StepNavigation>
        </div>
        <span>
          <button class="w-[20px] h-[20px]" @click="closeSheetAddChild">
            <img src="../../assets/images/close.png" alt="Close" />
          </button>
        </span>
      </div>

      <div style="flex: 1">
        <h1 class="text-left text-[14px] text-primary mt-10 mb-6 font-semibold">
          Add a child
        </h1>
        <hr
          style="
            width: calc(100% + 2rem);
            margin-left: -1rem;
            color: #e8e8e8;
            margin-bottom: 20px;
          "
        />

        <div v-if="currentstep == 1" class="addMissionExplChild">
          <AppInput
            v-model="child.first_name"
            required
            type="text"
            name="Enter your first child’s name"
            label="first_name"
          />

          <AppInput
            v-model="child.last_name"
            required
            type="text"
            name="Enter your child’s last name"
            label="last_name"
          />
        </div>

        <div v-if="currentstep == 2" class="addMissionExplChild">
          <AppInput
            v-model="searchQuery"
            :required="true"
            @input="isTyping = true"
            :hasLoader="isFetchingUsername"
            type="text"
            name="Enter a username for your child"
            label="username"
            style="margin-bottom: 0"
          />

          <div v-if="searchQuery !== ''">
            <small
              v-if="isUsernameValid !== null && isUsernameValid === true"
              style="
                font-size: 10px;
                margin-left: 10px;
                line-height: 10px;
                color: #00d074;
              "
              >Username is available</small
            >

            <small
              v-if="isUsernameValid !== null && isUsernameValid === false"
              style="
                font-size: 10px;
                margin-left: 10px;
                line-height: 10px;
                color: #ff3149;
              "
              >Username is not available</small
            >
          </div>
        </div>

        <div v-if="currentstep == 3" class="addMissionExplChild">
          <div class="text-xs font-semibold text-secondary mb-[10px]">
            Enter {{ child.first_name }}'s date of birth
          </div>
          <dropdown-datepicker
            :on-day-change="onDayChange"
            :on-month-change="onMonthChange"
            :on-year-change="onYearChange"
            defaultDateFormat="dd-mm-yyyy"
            displayFormat="dmy"
            submitFormat="yyyy-mm-dd"
            :maxDate="todaysDate"
            :required="true"
            :defaultDate="getDefaultDate"
          ></dropdown-datepicker>

          <p
            style="
              margin-bottom: 10px;
              margin-top: 20px;
              pointer-events: none;
              font-size: 11px;
              color: #7a615a;
              font-weight: 600;
            "
          >
            Select {{ child.first_name }}'s gender
          </p>

          <select
            name="gender"
            @change="onSelectChange($event)"
            class="gender-select"
            v-model="gender"
          >
            <option value="" disabled selected hidden>Gender</option>
            <option value="m">Boy</option>
            <option value="f">Girl</option>
          </select>
        </div>

        <div
          v-if="currentstep == 4"
          class="addMissionExplChild"
          style="display: flex; justify-content: center; flex-direction: column"
        >
          <div style="display: flex !important; justify-content: center">
            <div>
              <label class="mb-4 -mt-3 text-xs font-bold leading-4 block"
                >Enter a PIN for your child
              </label>
              <PinInput @enter="getPass" class="addChildPinInput" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentstep == 1" class="flex justify-between mt-6">
        <button
          :style="[validateStep_1 ? { opacity: '.5' } : {}]"
          @click="changeStep(1)"
          :disabled="validateStep_1"
          class="bg-[#58C1E1] w-full h-[44px] rounded-[100px] py-3 px-12 text-white text-xs font-bold"
        >
          Next
        </button>
      </div>

      <div v-if="currentstep == 2" class="flex justify-between mt-6">
        <button
          :style="[validateStep_2 || !isUsernameValid ? { opacity: '.5' } : {}]"
          @click="changeStep(2)"
          :disabled="validateStep_2 || !isUsernameValid"
          class="bg-[#58C1E1] w-full h-[44px] rounded-[100px] py-3 px-12 text-white text-xs font-bold"
        >
          Next
        </button>
      </div>

      <div v-if="currentstep == 3" class="flex justify-between mt-6">
        <button
          :style="[validateStep_3 ? { opacity: '.5' } : {}]"
          @click="changeStep(3)"
          :disabled="validateStep_3"
          class="bg-[#58C1E1] w-full h-[44px] rounded-[100px] py-3 px-12 text-white text-xs font-bold"
        >
          Next
        </button>
      </div>

      <div v-if="currentstep == 4" class="flex justify-between mt-6">
        <button
          :style="[validateStep_4 ? { opacity: '.5' } : {}]"
          @click="changeStep(4)"
          :disabled="validateStep_4"
          class="bg-[#58C1E1] w-full h-[44px] rounded-[100px] py-3 px-12 text-white text-xs font-bold"
        >
          Next
        </button>
      </div>
    </Sheet>

    <Sheet :show.sync="sheetChildEdit" :on-close="closeSheetEditChild">
      <div class="edit_child_title" slot="title">
        Edit {{ editChild.first_name }}'s Info
      </div>
      <div style="flex: 1">
        <img
          :src="editChild.avatar"
          class="rounded-full object-cover w-[80px] h-[80px] mx-auto"
          alt="Avatar"
        />
        <div
          class="text-center mb-6 edit_child_name"
          style="color: var(--main)"
        >
          {{ editChild.first_name }}
        </div>
        <AppInput
          name="Username"
          label="username"
          v-model="editChild.username"
          required
        />
        <AppInput
          name="First Name"
          label="first_name"
          v-model="editChild.first_name"
          required
        />
        <AppInput
          name="Last Name"
          label="last_name"
          v-model="editChild.last_name"
          required
        />

        <label
          for="childPin"
          style="font-size: 12px; font-weight: 600; color: var(--secondary)"
          >PIN</label
        >
        <div
          class="global-input-style"
          style="
            padding: 0;
            display: flex;
            align-items: center;
            overflow: hidden;
            margin-top: 2px;
          "
        >
          <svg
            v-if="!btnText"
            @click="showPassword"
            width="30"
            height="30"
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            style="margin: 0 10px 0 15px"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>
          <svg
            v-else
            @click="showPassword"
            width="30"
            height="30"
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            style="margin: 0 10px 0 15px"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
            />
          </svg>
          <input
            id="childPin"
            style="
              border: none;
              padding: 15px;
              width: 100%;
              box-shadow: none;
              background: transparent;
            "
            placeholder="Password"
            spellcheck="false"
            v-model="editChild.password"
            :type="type"
            readonly
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 mr-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#01a4c0"
            @click="showResetPin(activeChild)"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
        </div>
      </div>
      <div class="flex justify-between">
        <button
          @click="deleteChild(activeChild)"
          class="bg-[#FF4E78] rounded-[100px] py-3 px-12 text-white text-xs font-bold"
        >
          Delete Child
        </button>
        <button
          @click="editChildSave"
          class="bg-[#58C1E1] rounded-[100px] py-3 px-12 text-white text-xs font-bold"
        >
          Save
        </button>
      </div>
    </Sheet>

    <Sheet :show.sync="sheetResetPin" :on-close="closeSheetResetPin">
      <div style="flex: 1">
        <label
          for="pin"
          style="
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            width: 100%;
            margin: 0 auto;
            display: block;
          "
          >Type the new PIN code</label
        >
        <AppPinInput
          id="pin"
          @enter="getPin"
          style="margin: 20px auto 30px auto; width: 80%; font-weight: bold"
        />
      </div>
      <div class="flex justify-center mt-6">
        <button
          @click="resetPin"
          class="bg-[#58C1E1] rounded-[100px] py-3 px-12 text-white text-xs font-bold"
        >
          Reset PIN
        </button>
      </div>
    </Sheet>

    <Sheet
      :show.sync="sheetChildAddedInfo"
      :on-close="closeSheetChildAddedInfo"
    >
      <h1 class="text-[24px] font-bold mb-8 text-center">Child added!</h1>
      <div
        v-if="lastAddedChildGetter !== null"
        class="text-center"
        style="flex: 1"
      >
        <img
          :src="lastAddedChildGetter.avatar"
          class="w-[100px] h-[100px] mb-8 mx-auto"
          alt="Avatar"
        />
        <h3 class="text-[20px] font-bold">
          {{ lastAddedChildGetter.first_name }}
          {{ lastAddedChildGetter.last_name }}
        </h3>
        <p class="text-[14px] text-[#AAAAAA]">
          @{{ lastAddedChildGetter.username }}
        </p>
        <p class="text-[14px] text-[#AAAAAA]">
          <!-- {{ lastAddedChildGetter.date_birth  }} -->
          {{ formattedBirthdate }}
        </p>
        <p class="text-[12px] text-[#AAAAAA] my-12">
          Give this information to {{ lastAddedChildGetter.last_name }}! Don’t
          worry if you forget the passcode, you can access it from the settings!
        </p>

        <h3 class="text-[20px] font-bold mb-2">PIN Code</h3>
        <div class="flex font-bold text-primary justify-center gap-[10px]">
          <!-- {{lastAddedChildGetter.password}}
            {{lastAddedChildGetter}} -->
          <div
            class="rounded-full border border-primary text-xl w-[50px] h-[50px] flex items-center justify-center font-bold"
          >
            {{ lastAddedChildGetter.password[0] }}
          </div>
          <div
            class="rounded-full border border-primary text-xl w-[50px] h-[50px] flex items-center justify-center font-bold"
          >
            {{ lastAddedChildGetter.password[1] }}
          </div>
          <div
            class="rounded-full border border-primary text-xl w-[50px] h-[50px] flex items-center justify-center font-bold"
          >
            {{ lastAddedChildGetter.password[2] }}
          </div>
          <div
            class="rounded-full border border-primary text-xl w-[50px] h-[50px] flex items-center justify-center font-bold"
          >
            {{ lastAddedChildGetter.password[3] }}
          </div>
        </div>
      </div>
      <button
        @click="closeSheetChildAddedInfo"
        class="bg-[#58C1E1] w-full h-[44px] rounded-[100px] py-3 px-12 text-white text-xs font-bold"
      >
        Finish
      </button>
    </Sheet>
  </div>
</template>

<script>
import AppChildrenActivity from "../../components/AppChildrenActivity";
import AppPinInput from "../../components/AppPinInput";
import { mapGetters, mapActions } from "vuex";
import status from "../../utils/statusCode";
import axios from "axios";
import config from "../../config";
import PinInput from "../../components/PinInput";
import StepNavigation from "../../components/wizard/StepNavigation";
import Sheet from "../../components/Sheet";
import swal from "sweetalert";
import moment from "moment";
import DropdownDatepicker from "vue-dropdown-datepicker";
import _ from "lodash";

export default {
  components: {
    AppChildrenActivity,
    Sheet,
    PinInput,
    StepNavigation,
    AppPinInput,
    DropdownDatepicker,
    AppInput: () =>
      import(/* webpackChunkName: "AppInput" */ "../../components/AppInput"),
  },
  data() {
    return {
      isUsernameValid: null,
      isTyping: false,
      isFetchingUsername: false,
      searchQuery: "",
      birthday: null,
      birthmonth: null,
      birthyear: null,
      gender: "",
      key: "",
      model: null,
      scrollPosition: null,
      sheetChildAdd: false,
      sheetChildAddedInfo: false,
      sheetChildEdit: false,
      sheetResetPin: false,
      childResetPinId: null,
      childResetPinPassword: null,
      modalKey: 0,
      btnText: true,
      type: "password",
      currentstep: 1,
      step: 1,
      active: 1,
      steps: [
        {
          id: 1,
          title: "1",
          icon_class: "fa fa-map-marker",
        },
        {
          id: 2,
          title: "2",
          icon_class: "fa fa-folder-open",
        },
        {
          id: 3,
          title: "3",
          icon_class: "fa fa-paper-plane",
        },
        {
          id: 4,
          title: "4",
          icon_class: "fa fa-paper-plane",
        },
      ],

      //////////////////////////

      childIcon: "../assets/images/Ellipse 1.png",
      activeChild: 0,
      modal: false,
      componentKey: 0,
      childLogsId: null,
      isLoading: true,
      activeAvatar: true,
      error: "",
      changeTodefault: false,
      modalChildAdd: false,
      editModal: false,
      uploadedAvatarBase64: null,
      currentEditAvatar: null,
      childBalance: null,
      childUsername: null,
      editChild: {
        index: "",
        first_name: "",
        last_name: "",
        username: "",
        date_birth: "",
        avatar: "",
        password: "",
      },
      modalChild: false,
      child: {
        name: "",
        first_name: "",
        last_name: "",
        username: "",
        date: "",
        gender: "",
        password: "",
        passwordConfirm: "",
        file: {},
      },
    };
  },
  computed: {
    formattedBirthdate() {
      if (!this.lastAddedChildGetter.date_birth) return "";
      const dateParts = this.lastAddedChildGetter.date_birth.split("-");
      if (dateParts.length === 3) {
        const [year, month, day] = dateParts;
        return `${day}-${month}-${year}`;
      }
      return this.lastAddedChildGetter.date_birth; // Return the input value if it's not in the expected format
    },
    ...mapGetters([
      "getSetupChildrens",
      "childrensActivity",
      "missionWishlistGetter",
      "transactionsGetter",
      "lastAddedChildGetter",
    ]),
    getDefaultDate() {
      return this.birthyear + "-" + this.birthmonth + "-" + this.birthday;
    },
    todaysDate() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    validateStep_1() {
      if (this.child.last_name != "" && this.child.first_name != "") {
        return false;
      } else {
        return true;
      }
    },
    validateStep_2() {
      if (this.child.username != "") {
        return false;
      } else {
        return true;
      }
    },
    validateStep_3() {
      if (
        this.birthday != null &&
        this.birthmonth != null &&
        this.birthyear != null &&
        this.gender != ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    validateStep_4() {
      if (this.child.password != "" && this.child.password.length == 4) {
        return false;
      } else {
        return true;
      }
    },
    maxDate() {
      return new Date().toISOString().split("T")[0];
    },
  },
  methods: {
    ...mapActions([
      "fetchChildrens",
      "fetchChildrensActivity",
      "deleteChildAction",
      "editChildAction",
      "addChildAction",
      "deleteLog",
      "deleteLogs",
      "deleteGuardianChild",
      "fetchMissionWishlistAction",
      "fetchTransactionsAction",
      "TOAST",
      "fetchUser",
    ]),
    onSelectChange(event) {
      this.gender = event.target.value;
      console.log(event.target.value);
    },
    onDayChange(day) {
      if (day < 10) {
        this.birthday = "0" + day;
      } else {
        this.birthday = day;
      }
    },
    onMonthChange(month) {
      if (month < 10) {
        this.birthmonth = "0" + month;
      } else {
        this.birthmonth = month;
      }
    },
    onYearChange(year) {
      this.birthyear = year;
    },
    stepBack(step) {
      if (step > 1) {
        this.currentstep = this.currentstep - 1;
      }
      console.log("from back");
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    showResetPin(id) {
      this.sheetChildEdit = false;
      this.openSheetResetPin(id);
    },
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.btnText = false;
      } else {
        this.type = "password";
        this.btnText = true;
      }
    },
    changeStep(step) {
      if (step === 1) {
        if (this.child.first_name != "" && this.child.last_name != "") {
          this.currentstep = 2;
        }
      }

      if (step === 2) {
        if (this.child.username != "") {
          this.currentstep = 3;
        }
      }

      if (step === 3) {
        if (
          this.birthday != null &&
          this.birthmonth != null &&
          this.birthyear != null &&
          this.gender != ""
        ) {
          this.currentstep = 4;
        }
      }

      if (step === 4) {
        if (this.child.password != "") {
          this.addChild();
        }
      }
    },
    getPin(pin) {
      this.childResetPinPassword = pin;
    },
    openSheetResetPin(child) {
      this.childResetPinId = child;
      this.sheetResetPin = true;
    },
    closeSheetResetPin() {
      this.childResetPinId = null;
      this.childResetPinPassword = null;
      this.sheetResetPin = false;
      this.fetchChildren();
    },
    async resetPin() {
      const token = localStorage.getItem("guardian_token");
      const userId = localStorage.getItem("guardian_user_id");
      const family = localStorage.getItem("guardian_family_id");

      const response = await axios.post(
        "/change/password/child?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family +
          "&child_id=" +
          this.childResetPinId +
          "&password=" +
          this.childResetPinPassword
      );

      this.closeSheetResetPin();
      this.TOAST(response.data.message);
    },
    openSheetChildAddedInfo() {
      this.sheetChildAddedInfo = true;
    },
    closeSheetChildAddedInfo() {
      this.sheetChildAddedInfo = false;
      this.$store.state.lastAddedChild = null;
      // location.reload();
      setTimeout(() => {
    window.location.href = '/guardian';
  }, 10);
    },
    openSheetAddChild() {
      this.sheetChildAdd = true;
    },
    closeSheetAddChild() {
      this.sheetChildAdd = false;
      this.child.username = "";
      this.child.first_name = "";
      this.child.last_name = "";
      this.child.date = "";
      this.child.password = "";
      this.child.passwordConfirm = "";
      this.uploadedAvatarBase64 = null;
      this.currentstep = 1;
      this.modalKey = -1;
      this.birthday = null;
      this.birthmonth = null;
      this.birthyear = null;
      this.gender = "";
      this.searchQuery = "";
      this.isUsernameValid = null;
      this.isTyping = false;
      this.isFetchingUsername = false;
    },
    openSheetEditChild() {
      this.sheetChildEdit = true;
    },
    closeSheetEditChild() {
      this.sheetChildEdit = false;
      this.btnText = true;
    },
    addChildMethod() {
      this.openSheetAddChild();
    },
    stepChanged: function (step) {
      this.currentstep = step;
    },
    setChildGender(value) {
      this.child.gender = value;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    getPass(pin) {
      this.child.password = pin;
    },
    childActivate(id, balance, username) {
      this.childUsername = username;
      this.childBalance = balance;
      this.activeChild = id;
      this.childLogsId = id;
      this.fetchChildrensActivity(id);
      this.fetchMissionWishlistAction(id);
      this.fetchTransactionsAction(id);
    },
    deleteChild(id) {
      swal({
        title: "Are you sure?",
        text: "This child will be deleted!",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.deleteChildAction(id);
          this.activeChild = 0;
          if (Object.keys(this.getSetupChildrens).length > 0) {
            const firstChild = this.$refs.childRef[0];
            firstChild.click();
          }
          this.sheetChildEdit = false;
        }
      });
    },
    deleteLogSubmit(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Do you really want to delete this log?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.deleteLog(id);
        }
      });
    },
    openEditModal(index) {
      this.editOrNormal = false;
      this.editModal = true;
      this.btnText = true;
      this.childIndex = index;
      this.editChild.id = this.getSetupChildrens[this.childIndex].id;
      this.editChild.first_name =
        this.getSetupChildrens[this.childIndex].first_name;
      this.editChild.last_name =
        this.getSetupChildrens[this.childIndex].last_name;
      this.editChild.index = this.childIndex;
      this.editChild.username =
        this.getSetupChildrens[this.childIndex].username;
      this.editChild.date_birth =
        this.getSetupChildrens[this.childIndex].date_birth;
      this.editChild.password =
        this.getSetupChildrens[this.childIndex].password;
      this.editChild.avatar = this.getSetupChildrens[this.childIndex].avatar;
      this.currentEditAvatar = this.getSetupChildrens[this.childIndex].avatar;
      this.openSheetEditChild();
    },
    resolveImage(src) {
      return config.HOST + src;
    },
    openModal() {
      this.modal = true;
    },
    async addChild() {
      this.error = "";
      const { child } = this;
      this.child.date =
        this.birthyear + "-" + this.birthmonth + "-" + this.birthday;
      this.child.gender = this.gender;
      const passRegex = /^\d{4}$/;

      if (!passRegex.test(child.password)) {
        this.error = "Password must be a 4 digit code!";
      } else {
        await Promise.all([this.addChildAction(child)]).then(() => {
          this.closeSheetAddChild();
        });

        this.child.username = "";
        this.child.first_name = "";
        this.child.last_name = "";
        this.child.date = "";
        this.child.password = "";
        this.child.passwordConfirm = "";
        this.uploadedAvatarBase64 = null;
        this.currentstep = 1;
        this.modalKey = -1;
        this.birthday = null;
        this.birthmonth = null;
        this.birthyear = null;
        this.gender = "";
        this.searchQuery = "";
        this.isUsernameValid = null;
        this.isTyping = false;
        this.isFetchingUsername = false;
      }
    },
    async editChildSave() {
      this.editChild.avatar = this.currentEditAvatar;
      await Promise.all([this.editChildAction(this.editChild)]).then(() => {
        this.closeSheetEditChild();
      });
      this.editChild = {
        index: "",
        first_name: "",
        last_name: "",
        username: "",
        date_birth: "",
      };
    },
    async fetchChildren() {
      const token = localStorage.getItem("guardian_token");
      const userId = localStorage.getItem("guardian_user_id");
      const family = localStorage.getItem("guardian_family_id");

      await axios
        .post(config.API_HOST + "/children", {
          userId: userId,
          token: token,
          family_id_check: family,
        })
        .then((response) => {
          const { statusCode } = response.data;
          if (status.success(statusCode)) {
            this.$store.commit("FETCH_SETUP_CHILDRENS", response.data.data);

            if (response.data.data.length > 0) {
              localStorage.setItem(
                "guardian_setup_active_child_id",
                response.data.data[0].users_id
              );
              this.childActivate(
                response.data.data[0].users_id,
                this.getSetupChildrens[0].balance,
                this.getSetupChildrens[0].username,
                this.getSetupChildrens[0].password
              );
              this.$store.state.globaLoading = false;
            }
          } else if (status.invalidToken(statusCode)) {
            this.deleteStorage();
          }
        })
        .catch((error) => {
          throw error;
        });
    },
    checkUsername: function (searchQuery) {
      this.isFetchingUsername = true;
      this.isUsernameValid = null;

      if (searchQuery !== "") {
        axios
          .get(config.API_HOST + `/checkusername?username=${searchQuery}`)
          .then((response) => {
            const { statusCode } = response.data;
            if (status.success(statusCode)) {
              this.isFetchingUsername = false;

              if (response.data.data === false) {
                this.isUsernameValid = true;
                this.child.username = searchQuery;
              } else {
                this.isUsernameValid = false;
              }
            } else if (status.invalidToken(statusCode)) {
              this.deleteStorage();
            } else if (status.error(statusCode)) {
              this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            }
          });
      } else {
        this.isFetchingUsername = false;
      }
    },
  },
  created() {
    this.fetchChildren();
    this.fetchUser();
    console.log("lastAddedChildGetter", this.lastAddedChildGetter);
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    Promise.all([this.fetchUser(), this.fetchChildren()]).then(() => {
      this.isLoading = false;
    });
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  watch: {
    searchQuery: _.debounce(function () {
      this.isTyping = false;
    }, 500),
    isTyping: function (value) {
      if (!value) {
        this.checkUsername(this.searchQuery);
      }
    },
    lastAddedChildGetter: function (value) {
      if (value !== null) {
        this.openSheetChildAddedInfo();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit_child_title {
  padding-top: 20px;
  font-size: 20px;
  font-weight: 700;
}
.edit_child_name {
  color: #7a6159 !important;
  font-weight: 700;
}
#balance {
  background: #56b897;
  background: #56b897;
  background: linear-gradient(180deg, #5cc5a2 0%, #25b484 100%);
  width: 100%;
  height: 135px;
  border-radius: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  * {
    transition: all 200ms ease-in-out;
  }

  .balance_text {
    color: white;
    padding: 10px;

    &__title {
      font-size: 17px;
      font-weight: 600;
    }

    &__amount {
      font-size: 50px;
      font-weight: bold;
      font-weight: 700;
      letter-spacing: 0.02rem;
    }
  }

  .balance_button {
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 50%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;

    &:active {
      opacity: 0.8;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
}
.dashboardChildren {
  display: grid;
  grid-template-columns: 10fr 3fr;
  height: 100%;

  &__container {
    margin-right: 20px;

    &__mobile {
      display: none;
    }

    &__title {
      font-size: 35px;
      line-height: 42px;
      color: #7a615a;
      margin-bottom: 30px;
    }

    &__children {
      display: flex;
      margin: 30px 0;
    }

    &__activity {
      background: #f8f5ed;
      border-radius: 40px;

      &__actions {
        display: flex;
        padding: 30px;
        justify-content: space-between;

        &__title,
        &__clear {
          font-size: 20px;
          line-height: 24px;

          // color: #7a615a;
          color: #7a615a;
        }

        &__clear {
          // color: #ff4f79;
          color: #7a615a;
          // cursor: pointer;
        }
      }
    }
  }
}

.dashboardChildrens__container__childrenElem::-webkit-scrollbar {
  background-color: #e5e5e5;
  border: 2px solid #e5e5e5;
  border-radius: 100px;
  height: 5px;
}

.dashboardChildrens__container__childrenElem {
  display: flex;
  flex-direction: row;
  // width: 51vw;
  width: 100%;
  padding-bottom: 10px;
  overflow-x: scroll !important;
}

.dashboardChildren__container__children__add {
  margin-left: 20px;
  margin-top: -10px;
}

.appForm__avatars__second {
  display: block !important;
}

@media screen and (max-width: 820px) {
  .appForm__avatars {
    &__inline {
      display: none;
    }
  }

  .appForm__avatars__second {
    display: block;
    margin-top: 15px;
  }
}

@media screen and (max-width: 1200px) {
  .dashboardChildren {
    grid-template-columns: 9fr 4fr;
  }
}

@media screen and (max-width: 800px) {
  .dashboardChildren {
    grid-template-columns: 1fr;

    &__container {
      margin-right: 0;

      &__mobile {
        display: block;
        margin-bottom: 20px;
      }
    }

    &__sidebar {
      display: none;
    }
  }
}

.dashboardChildren__container__children__add_mobile {
  display: none;
}

.normal-text-title {
  color: #806157;
  font-size: 20px;
  line-height: 42px;
  font-weight: 800;
}
.normal-text {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #806157;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 820px) {
  .dashboardChildren__container__title {
    text-align: center;
  }
  .appForm__avatars {
    margin-top: 10px;
  }
  .dashboardChildren__container {
    width: 100%;
    margin-left: 0;
  }
  .dashboardChildren__container__children {
    flex-direction: column;
    align-items: center;
  }
  .dashboardChildren__container__children__add {
    display: none;
  }
  .dashboardChildren__container__children__add_mobile {
    display: block;
  }
  .addChildButtonMobile {
    background: #36d5f2;
    box-shadow: 0px 4px 20px rgba(54, 213, 242, 0.5);
    width: 100%;
    border-radius: 70px;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: bold;
    color: white;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .appChildrenActivity__close {
    max-width: 15px;
    max-height: 15px;
  }
  .appChildrenActivity__profile__image {
    max-width: 40px;
    max-height: 40px;
  }
  .appChildrenActivity__profile__title {
    font-size: 12px;
    line-height: 12px;
  }
  .dashboardChildren__container__activity__actions__clear {
    font-size: 14px;
    background: #ff4f79;
    color: white;
    padding: 1px 15px;
    border-radius: 70px;
  }
  .appChildrenSettings {
    display: none;
  }
  .dashboardChildren {
    display: block;
  }
  .dashboardChildrens__container__childrenElem {
    width: 100%;
  }
  .missionChild__image,
  .missionChild__username {
    margin-top: 0;
  }
  .missionChild__active,
  .missionChild {
    padding: 5px;
    height: 60px;
    align-items: center;
  }
  .missionChild__image {
    width: 40px;
    height: 40px;
  }
  .missionChild__username {
    font-size: 14px;
  }
  .appForm__avatars > .appAvatars > .appAvatars__avatar {
    width: 70px !important;
    height: 70px !important;
  }
  .appForm__avatars__second > .appAvatars__avatar {
    width: 70px !important;
    height: 70px !important;
  }
  // .appAvatars__custom__active > img,
  // .appAvatars__custom > img {
  //   margin-top: 20px !important;
  //   margin-left: 0 !important;
  // }
  .dashboardChildrens__container__childrenElem::-webkit-scrollbar {
    display: none;
  }
  .dashboardChildren__container__title {
    font-size: 25px;
  }
  .dashboardChildren__container {
    margin-top: 0;
  }
}

.dashboardChildrens__container__childrenElem::-webkit-scrollbar,
.dashboardFunds__center__container__children::-webkit-scrollbar {
  display: none !important;
}

.passLabel {
  font-size: 12px;
  font-weight: 600;
  color: var(--secondary);
}

.vs-dialog__content::-webkit-scrollbar {
  display: none;
}

.appForm__title {
  top: 0;
}

.appForm__description {
  display: block;
  margin-top: 15px;
  margin-bottom: 20px;
}

.disclaimer {
  background: #ff4f79;
  font-weight: bold;
  color: white;
  padding: 5px 20px 7px 20px;
  border-radius: 100px;
  span {
    color: yellow;
  }
}

.barProgress[value]::-webkit-progress-value {
  background: linear-gradient(90deg, #fc4a1a 0%, #f7b733 100%);
  border-radius: 40px;
  height: 23px;
}
.barProgress::-webkit-progress-bar {
  border-radius: 40px;
  height: 23px;
  background: #ece9e6;
  margin-left: 20px;
}
.barProgress[value]::-webkit-progress-value:before {
  content: "80%";
  position: absolute;
  right: 0;
  top: -125%;
}
.barProgress[value]::-webkit-progress-value::after {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  border-radius: 100%;
  right: 7px;
  top: 7px;
  background-color: white;
}
.barProgress {
  height: 23px;
}

.skillProgress {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.levelTitle {
  font-weight: 600;
  color: #7a615a;
}

.wishlist_container {
  border-radius: 40px;
}

.vs-card__group .vs-card__group-next {
  position: relative;
  right: 0;
  left: unset;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  margin-bottom: 20px;
}

.stories {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  overflow: auto hidden;
  margin-top: 10px;
}

// .stories__item {
//   border-color: #01a3c059;
//   border-width: 2px;
//   border-radius: 20px;
//   user-select: none;
//   margin-right: 10px;

// }

// .stories__item button {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   border: 0;
//   background: transparent;
//   // width: 70px;
//   cursor: pointer;
//   outline: 0;
// }
.stories__item {
  user-select: none;
  margin-right: 15px;
}

.stories__item button {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0;
  background: transparent;
  cursor: pointer;
  outline: 0;
}

.stories__item-picture {
  width: 55px;
  height: 55px;
  position: relative;
}

.stories__item-picture img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  pointer-events: none;
}
.stories__item-button {
  display: flex;
  flex-direction: row;
}
// .stories__item-username {
//   // width: 64px;
//   padding: 7px;
//   font-weight: 800;
//   color: #01a3c059;
//   font-size: 17px;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   // max-width: 55px;
// }
.stories__item-username {
  color: #01a3c038;
  border: 2px solid #01a3c038;
  border-radius: 60px;
  padding: 10px;
  padding-inline: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // max-width: 120px;
}

.stories__item--active {
  border-color: #01a4c0 !important;
  color: #01a4c0 !important;
}

.stories__item-manage {
  margin-left: 10px;
  padding-bottom: 5px;
  display: inline-block;
}

// .stories__item--active .stories__item-picture::before {
//   content: "";
//   user-select: none;
//   position: absolute;
//   left: 0;
//   right: 0;
//   bottom: -6px;
//   z-index: -1;
//   background: #01a4c0;;
//   height: 2px;
//   width: 40px;
//   margin: 0 auto;
// }
.stories__item-username--active {
  color: #01a4c0;
  border-color: #01a4c0;
}
.stories__item--active .stories__item-picture::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -6px;
  flex-direction: row;
}

.addChildButton {
  background: var(--main);
  border-radius: 50%;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;

  &:active {
    opacity: 0.8;
  }

  svg {
    width: 23px;
    height: 23px;
  }
}

.editChildButton {
  // width: 97%;
  // bottom: -28px;
  // background: var(--main);
  // color: white;
  // justify-content: center;
  // display: flex;
  // align-items: center;
  // margin-top: 5px;
  // font-size: 10px;
  // border-radius: 100px;
  // cursor: pointer;
  // padding: 2px;
  // font-weight: 500;
  width: 97%;
  bottom: -28px;
  background: var(--main);
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 10px;
  border-radius: 100px;
  cursor: pointer;
  padding: 2px;
  font-weight: 500;
}

.updateChildButton {
  background: var(--red);
  border: none;
  width: 100%;
  color: white;
  padding: 15px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 700;
  margin: 0 auto;
  cursor: pointer;
}

.childSkillProgress {
  // box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  // background-color: var(--cool);
  background-color: white;
  width: 100%;
  padding: 30px;
  border-radius: 15px;
}
.childSkillProgress h4 {
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  color: var(--secondary);
}
.childSkillProgress progress {
  appearance: none;
  border: 0;
  width: 100%;
  height: 15px;
  color: #51aded;
  background-color: #ccc;
  border-radius: 10px;
}
.childSkillProgress progress::-moz-progress-bar {
  background-color: #51aded;
  border-radius: 10px;
}
.childSkillProgress progress::-webkit-progress-bar {
  background-color: #ccc;
  border-radius: 10px;
}
.childSkillProgress progress::-webkit-progress-value {
  position: relative;
  background-color: #51aded;
  border-radius: 10px;
}
.childSkillProgress p {
  font-size: 80%;
  padding: 0;
  margin: 0;
  text-align: center;
}
.childSkillProgress .fileElem {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 300px;
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
  height: 50px;
  opacity: 0;
  cursor: pointer;
}
.childSkillProgress .fileElem:focus {
  outline: none;
}
.childSkillProgress .fileSelect {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  text-align: left;
  padding-left: 110px;
  width: 100%;
  height: 50px;
  background-color: #51aded;
  border-radius: 3px;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  position: relative;
  font-size: 20px;
  line-height: 50px;
  color: white;
}
.childSkillProgress .fileSelect:before {
  font-family: Entypo;
  content: "\E711";
  position: absolute;
  top: 0;
  left: 80px;
}

.goal-item {
  background: white;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: 5px;
  border-radius: 15px;
  align-items: center;
}

.goal-img {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  margin-right: 15px;
  width: 80px;
  height: 80px;
  padding: 5px;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.goal-description h1 {
  font-size: 12px;
  display: -webkit-box;
  height: 31px;
  font-weight: 600;
  line-height: 15px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--secondary);
}

.goal-description p {
  font-size: 18px;
  color: var(--main);
  font-weight: 700;
}
.walletScroll {
  display: flex;
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-padding: 0.5rem;
}
.walletScroll__item__3 {
  --stop: normal;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  flex: 0 0 250px;
  scroll-snap-align: start;
  scroll-snap-stop: var(--stop);
}

.walletScroll__item__3:last-child {
  margin-right: 0;
}

.guardian-login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  margin-bottom: auto;

  &__content {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background: white;
    border: 1px solid #dedede;
    padding: 20px;
    width: 85%;
  }

  &__input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 20px;
    border: 1px solid #dedede;
    border-radius: 5px;
    outline: 0;
    height: 50px;
    color: #7a615a;
    font-size: 14px;
    background: #fafafa;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 0 7px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 7px;
    -webkit-appearance: none;
  }

  &__title {
    margin-bottom: 40px;
    text-align: center;
    color: #7a6159;
    width: 85%;

    h1 {
      font-size: 20px;
    }

    p {
      margin-top: 5px;
      font-size: 13px;
    }
  }
}
</style>
